import './AlertsCard.css';

import { useSelector/*, useDispatch*/ } from 'react-redux';
import { /*useHistory,*/ Link } from "react-router-dom";



// import { openModal } from 'reducers/modalReducer';
// import { MODAL_TYPES } from 'view/ModalManager';


function AlertsCard() {
	// const dispatch = useDispatch();
	// const history = useHistory();
	const {
		isLoggedIn,
		propertyData,
		// portfolio,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		propertyData: state.property.propertyData,
		// portfolio: state.portfolio.portfolioData?.data || [],
	}));

	// const login = () => {
	// 	return history.push('/sign-in');
	// };
	// const openAlertsModal = () => {
	// 	// console.log('portfolio', portfolio);
	// 	// dispatch(openModal(MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL, { portfolioId: portfolioId || -1, propertyData }));
	// 	// dispatch(openModal(MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL, { propertyData }));
	// 	history.push(`/property/${propertyData?.address}/notifications`);
	// };

	// let button = <button className="btn btn-primary mt-5" onClick={login}>Get Alerts</button>;
	let button = <Link className="btn btn-primary mt-5" to={`/sign-in`}>Get Alerts</Link>;

	if (isLoggedIn) {
		// button = (<button className="btn btn-primary mt-5" onClick={openAlertsModal}>Get Alerts</button>);
		button = <Link className="btn btn-primary mt-5" to={`/property/${propertyData?.address}/notifications`}>Get Alerts</Link>;
	}

	return (
		<div className="overview-card overview-card-small">
			<div className="overview-card-content">

				<div className="fw-bold">Alerts and Notifications</div>
				<div className="alerts-card-content d-flex justify-content-center">
					{button}
				</div>
			</div>
		</div>
	);
}

export default AlertsCard;
